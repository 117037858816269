<template lang="pug">
.reset-password-confirm
  h1 Redefinir senha

  .form(v-if="!success")
    p Defina uma nova senha abaixo para acessar sua conta Magenta.

    form(@submit.prevent="resetPassword")
      TextInput(
        label="Nova senha",
        type="password",
        v-model="state.password",
        @blur="v$.password.$touch",
        :errors="v$.password.$errors",
        autocomplete="new-password")

      TextInput(
        label="Confirme a nova senha",
        type="password",
        v-model="state.passwordConfirmation",
        @blur="v$.passwordConfirmation.$touch",
        :errors="v$.passwordConfirmation.$errors",
        autocomplete="new-password")

      button(v-show="false", type="submit")

      .actions
        Button.large(label="Redefinir senha", rounded, to="", @click.prevent="resetPassword", :loading="loading")

  .success(v-else)
    p Sua senha foi redefinida! Agora você pode usar esta nova senha para acessar sua conta Magenta.

    .actions
      Button.large(
        label="Acessar conta",
        :to="{ name: 'sign-in' }",
        rounded
      )

</template>
<script setup lang="ts">
import type { FetchError } from 'ofetch'
import type { Ticket } from '~/services/auth'

import useVuelidate from '@vuelidate/core'

import { default as AuthService, decodeTicket } from '~/services/auth'
import { useAuth } from '~/store/auth'
import { required, minLength, sameAs } from '~/scripts/validators/validators'
import { useGtm } from '@gtm-support/vue-gtm'

const route = useRoute()

const notifications = useNotifications()
const gtm = useGtm()

definePageMeta({
  middleware: () => {
    const auth = useAuth()
    const notifications = useNotifications()

    if (auth.isAuthenticated) {
      notifications.warning("Você já está logado.")

      return navigateTo({ name: "dashboard" })
    }
  }
})

const state = reactive({
  password: "",
  passwordConfirmation: ""
})

const rules = {
  password: {
    required,
    minLength: minLength(6)
  },
  passwordConfirmation: {
    required,
    sameAsPassword: sameAs(computed(() => state.password), 'senha')
  }
}

const v$ = useVuelidate(rules, state)

const loading = ref(false)
const success = ref(false)
const ticket = ref<Ticket>()

const resetPassword = async () => {
  loading.value = true

  if (await v$.value.$validate()) {
    try {
      await AuthService.resetCredential({ email: ticket.value!!.email, ticket: ticket.value!!.ticket, password: state.password })

      gtm?.trackEvent({
        category: 'reset_password',
        action: 'password_reset',
        value: null
      })

      success.value = true
    } catch(error: FetchError | any) {
      if ("status" in error && error.status == 404) {
        notifications.warning("Não encontramos nenhuma conta Magenta com esse e-mail.")
      } else {
        captureException(new Error('Failed to reset password', { cause: error}))

        notifications.error("Ocorreu um erro ao tentar recuperar sua senha. Por favor, tente novamente mais tarde.")
      }
    }
  } else {
    notifications.warning("Verifique se a senha foi preenchida corretamente")
  }

  loading.value = false
}

onMounted(() => {
  try {
    ticket.value = decodeTicket(route.params.ticket as string)
  } catch(e) {
    captureException(new Error('Failed to decode password reset ticket', { cause: e}))

    notifications.warning("O link de redefinição de senha é inválido ou expirou. Por favor, tente novamente.")

    navigateTo({ name: "sign-in" })
  }
})
</script>
<style lang="sass" scoped>
.reset-password-confirm
  position: relative
  max-width: 708px
  margin: 0 auto
  padding: 0 14px
  text-align: center
  animation: fade-in .2s forwards

  h1
    text-align: left

  .actions
    display: flex
    flex-direction: column
    margin: 32px 0
    align-items: center

    .large
      text-align: center
      font-size: 24px
      padding: 4px 32px
</style>